import React, { useState, useEffect } from "react";
import {
  format,
  startOfWeek,
  addDays,
  subDays,
  startOfMonth,
  endOfMonth,
  endOfWeek,
  isSameMonth,
  isSameDay,
  parse,
  addMonths,
  subMonths,
} from "date-fns";
import "./calendar.css";
import { API, graphqlOperation } from "aws-amplify";
import axios from "axios";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import { withRouter, Link, useLocation } from "react-router-dom";
import request from "ajax-request";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import queryString from "query-string";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  createCompetition as CreateCompetition,
  deleteCompetition,
} from "../../graphql/mutations";
import {
  Col,
  Row,
  FormGroup,
  Modal,
  Label,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { listCompetitions, listAllCompetitions } from "../../graphql/queries";
import { select } from "redux-saga/effects";

function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

function Calendar() {
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [endDate, setEndDate] = useState(addDays(new Date(), 7));
  const [launchDate, setLaunchDate] = useState(new Date());
  const [isGlobalContest, setIsGlobalContest] = useState(true);
  const [hasCreativeDirection, setHasCreativeDirection] = useState(false);
  const [creativeDirectionRequired, setCreativeDirectionRequired] = useState(false);
  const [hasLanuageRestrictions, setHasLanguageRestrictions] = useState(false);
  const [languageRestrictions, setLanguageRestrictions] = useState("");
  const [platforms, setPlatforms] = useState(["TikTok"]);
  const [competitionType, setCompetitionType] = useState("Hybrid");
  const [isClosed, setIsClosed] = useState(false);
  const [clickedDate, setClickedDate] = useState(new Date());
  const [email, setEmail] = useState("");
  const [note, setNote] = useState("");
  const [artist, setArtist] = useState("");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [unavailableDates, setUnavailableDates] = useState([]);
  const [restrictedTerritories, setRestrictedTerritories] = useState();
  const [holdDates, setHoldDates] = useState([]);
  const [totalPrizes, setTotalPrizes] = useState(0);
  const [invoiceAmount, setInvoiceAmount] = useState(0);
  const [songName, setSongName] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [reserveModal, setReserveModal] = useState(false);
  const [competitions, setCompetitions] = useState([]);
  const [userInfo, setUserInfo] = useState();
  const fetchCompetitions = async () => {
    let nextToken = null;
    let allItems = [];

    do {
      const apiData = await API.graphql({
        query: listCompetitions,
        variables: {
          limit: 100000,
          filter: { endDate: { gt: 1716696000000 } },
          nextToken: nextToken, // Pass the nextToken here
        },
      });

      const items = apiData.data.listCompetitions.items;

      // Filter and process items
      const filteredItems = items.filter((e) => {
        if (e.startDate !== null && e._deleted !== true) {
          if (e.isConfirmed && e.isClosed !== true) {
            const tempUDates = unavailableDates;
            tempUDates.push({
              date: formatDate(new Date(e.startDate).toDateString()),
              title: `${e.organizer}`,
              email: e.organizerEmail,
            });
            setUnavailableDates(tempUDates);
          } else if (!e.isConfirmed) {
            const tempHDates = holdDates;
            tempHDates.push({
              date: formatDate(new Date(e.startDate).toDateString()),
              title: `${e.organizer}`,
              email: e.organizerEmail,
            });
            setHoldDates(tempHDates);
          }
        }
        return e._deleted !== true;
      });

      // Accumulate all items
      allItems = allItems.concat(filteredItems);

      // Update nextToken
      nextToken = apiData.data.listCompetitions.nextToken;
    } while (nextToken); // Continue while there is a nextToken

    // Finally, set the competitions state with all accumulated items
    await setCompetitions(allItems);
  };
  let location = useLocation();
  let params = queryString.parse(location.search);
  useEffect(() => {
    fetchCompetitions();
    request(
      {
        url: `https://www.songfluencer.me/portal2/inquiries/GetUser.cfm/?Token=${params.LoginToken}`,
        method: "POST",
        crossDomain: true,
      },
      function (err, res, body) {
        const bodyInJSON = JSON.parse(body);
        console.log("139",bodyInJSON);
        setEmail(bodyInJSON.Data[0].EMAILADDRESS_PRIMARY);
        setUserInfo(bodyInJSON.Data);
      }
    );
  }, []);

  const toggle = () => {
    setModal(!modal);
  };

  const toggleReserveModal = () => {
    setReserveModal(!reserveModal);
  };

  const renderHeader = () => {
    const dateFormat = "MMM yyyy";

    return (
      <div className="header row flex-middle">
        <div className="col col-start">
          <div className="icon" onClick={prevMonth}>
            chevron_left
          </div>
        </div>
        <div className="col col-center">
          <span>{format(currentMonth, dateFormat)}</span>
        </div>
        <div className="col col-end" onClick={nextMonth}>
          <div className="icon">chevron_right</div>
        </div>
      </div>
    );
  };

  const renderDays = () => {
    const dateFormat = "eee";
    const days = [];

    let startDate = startOfWeek(currentMonth);

    for (let i = 0; i < 7; i++) {
      days.push(
        <div className="col col-center" key={i}>
          {format(addDays(startDate, i), dateFormat)}
        </div>
      );
    }

    return <div className="days row">{days}</div>;
  };

  const calcTotalPrizes = (prizes) => {
    if (prizes) {
      let sum = 0;
      // let sum = prizes[0].includes("4th") ? 300 : 0;
      prizes.forEach((element, i) => {
        let val = element.split("$")[1];
        let label = element.split("$")[0];
        if (label.includes("-") && !label.includes("Followers")) {
          const rangeLow = element.split("-")[0];
          const rangeLowVal = rangeLow.substring(0, rangeLow.length - 2);
          const rangeHigh = element.split("-")[1].split(" Place")[0];
          const rangeHighVal = rangeHigh.substring(0, rangeHigh.length - 2);
          const multiplier = parseInt(rangeHighVal) - parseInt(rangeLowVal) + 1;
          val = val * multiplier;
        } else if (label.includes("Top")) {
          const amountOfWinners = parseInt(label.split(" ")[1]);
          val = amountOfWinners * val;
        }
        sum += parseFloat(val);
      });
      return "$" + sum;
    }
  };

  function returnPrizeDistribution() {
    console.log("total prizes", totalPrizes);
    if (competitionType === "Creative") {
      if (parseFloat(totalPrizes) < 3000) {
        return [
          `Top 20 Most Creative: $${Math.floor((totalPrizes * 0.8) / 20)}`,
          `Best Lip Sync: $${Math.floor((totalPrizes * 0.1))}`,
          `Best Dance: $${Math.floor(totalPrizes * 0.1)}`,
        ];
      } else {
        return [
          `Top 50 Most Creative: $${Math.floor((totalPrizes * 0.8) / 50)}`,
          `Best Lip Sync: $${Math.floor(totalPrizes * 0.1)}`,
          `Best Dance: $${Math.floor(totalPrizes * 0.1)}`,
        ];
      }
    } else if (competitionType === "Hybrid") {
      if (parseFloat(totalPrizes) < 2000) {
        return [
          `1st-5th Place: $${Math.floor((totalPrizes * 0.3) / 5)}`,
          `6th-10th Place: $${Math.floor((totalPrizes * 0.2) / 5)}`,
          `11th-15th Place: $${Math.floor((totalPrizes * 0.1) / 5)}`,
          `Top 40 Most Creative: $${Math.floor((totalPrizes * 0.4) / 40)}`,
        ];
      } else {
        return [
          `1st-5th Place: $${Math.floor((totalPrizes * 0.25) / 5)}`,
          `6th-10th Place: $${Math.floor((totalPrizes * 0.2) / 5)}`,
          `11th-20th Place: $${Math.floor((totalPrizes * 0.2) / 10)}`,
          `20th-30th Place: $${Math.floor((totalPrizes * 0.1) / 10)}`,
          `Top 48 Most Creative: $${Math.floor((totalPrizes * 0.25) / 50)}`,
        ];
      }
    } else {
      return [
        `1st-5th Place: $${Math.floor((totalPrizes * 0.3) / 5)}`,
        `6th-10th Place: $${Math.floor((totalPrizes * 0.2) / 5)}`,
        `11th-15th Place: $${Math.floor((totalPrizes * 0.1) / 5)}`,
        `16th-55th Place: $${Math.floor((totalPrizes * 0.4) / 40)}`,
      ];
    }

  }

  const determineAvailabilityUDI = (uDI, hDI) => {
    if (uDI.length > 0) {
      return (
        <>
          {uDI.map((e) => {
            return (
              <>
                <div className="cal-stat text-danger">
                  {unavailableDates[e].title}
                </div>
              </>
            );
          })}
        </>
      );
    }


    if (hDI.length === 0 && uDI.length === 0){
      return <div className="cal-stat text-success">Available</div>;
    }
  };

  const determineAvailabilityHDI = (uDI, hDI) => {
    if (hDI.length > 0) {
      return (
        <>
          {hDI.map((e, i) => {
            return (
              <>
                <div className="cal-stat text-warning">
                  {holdDates[e].title}
                </div>
              </>
            );
          })}
        </>
      );
    }
  };

  const removeHold = async (comp) => {
    await API.graphql(
      graphqlOperation(deleteCompetition, {
        input: { id: comp.id, _version: comp._version },
      })
    );
    window.location.reload();
  };

  const returnFormattedNote = () => {
    return `<p><b>Has Creative Direction:</b>${hasCreativeDirection}</br><b>Creative Direction Required:</b> ${hasCreativeDirection ? `${creativeDirectionRequired}` : ""}</br><b>Territories:</b> ${isGlobalContest ? "Global" : restrictedTerritories}</br><b>Language requirement:</b> ${hasLanuageRestrictions ? `${languageRestrictions}` : "None"}</br><b>Additional Notes:</b> ${note}</p>`;
  }

  async function createCompetition() {
    const apiCompetitionsData = await API.graphql({
      query: listAllCompetitions,
      variables: { limit: 100000 },
    });
    const formattedNote = returnFormattedNote();
    const inputData = {
      name: songName,
      image: "",
      audio: "",
      startDate: new Date(launchDate).getTime(),
      endDate: new Date(addDays(launchDate, 7)).getTime(),
      organizer: artist,
      organizerEmail: email,
      isActive: false,
      hasEnded: false,
      platforms:platforms,
      invoiceAmount: invoiceAmount,
      competitionType: competitionType,
      isClosed: isClosed,
      notes: formattedNote,
      campaignID: apiCompetitionsData.data.listCompetitions.items.length + 1,
      prizes: returnPrizeDistribution(),
      lastUpdated: new Date().getTime(),
    };
    await API.graphql(
      graphqlOperation(CreateCompetition, { input: inputData })
    );
    // window.location.reload();
  }

  const returnEmailHTML = (conflictDate, emails) => {
    return (
      <p>
        There is a hold conflict for Preffy on the date: $
        {formatDate(launchDate)}. Please resolve.
      </p>
    );
  };

  const handleClick = async (event) => {
    
    // Get Stripe.js instance
    // Call your backend to create the Checkout Session
    if (email.length > 0 && invoiceAmount > 0 && artist.length > 0) {
      setIsLoading(true);
      await createCompetition();
      const emailList = [
        { email: "meghan@songfluencer.com" },
        { email: email },
      ];
      holdDates.map((e) => {
        if (e.date === formatDate(launchDate)) {
          console.log("date equals", e.organizerEmail);
          emailList.push({ email: e.email });
        }
      });
      if (emailList.length >= 4) {
        await axios
          .post("https://mandrillapp.com/api/1.0/messages/send.json", {
            key: "md-fhneX5PkBIP4iGyWiZ_BeQ",
            message: {
              from_email: "info@genni.com",
              to: emailList,
              preserve_recipients: true,
              subject: "Preffy Hold Conflict",
              html: `<p>${email} put a hold on ${formatDate(
                launchDate
              )}. There is now a hold conflict for Preffy on that date. Please resolve.<br/></p>`,
            },
          })
          .then(function (response) {
            console.log(response);
          })
          .catch(function (error) {
            console.log(error);
          });
        window.location.reload();
      } else {
        window.location.reload();
      }
    }
    // window.location.reload();
    // if (isLoggedIn){
    //   welcomeCodeReturningOrganizers(email);
    // } else {
    //   welcomeCodeNewOrganizers(email);
    // }
  };
  const handlePlatformsChange = (e) => {
    if (e.target.value.includes(",")) {
      setPlatforms(e.target.value.split(","));
    } else {
      setPlatforms([e.target.value]);
    }
  };

  const handleRestrictedTerritoriesChange =(e) =>{
    setRestrictedTerritories(e.target.value);
  }

  Array.prototype.multiIndexOf = function (el) {
    var idxs = [];
    for (var i = this.length - 1; i >= 0; i--) {
      if (this[i].date === el) {
        idxs.unshift(i);
      }
    }
    return idxs;
  };

  const calculateConfirmedCompetitions = (day) => {
    let confirmedComps = 0;
    competitions.map((e) => {
      const newClickedDate = `${day}`.split("-");
      const clickedYears = newClickedDate[0];
      const clickedMonths = newClickedDate[1];
      const clickedDays = newClickedDate[2];
      const selectedDateInt = new Date(
        `${clickedMonths}/${clickedDays}/${clickedYears}`
      ).getTime();
      const midnightStartDate = new Date(e.startDate);
      midnightStartDate.setHours(0, 0, 0);
      if (
        selectedDateInt >= midnightStartDate.getTime() &&
        selectedDateInt <= e.endDate &&
        e.isConfirmed
      ) {
        confirmedComps++;
      }
    });

    return confirmedComps;
  };

  const renderCells = () => {
    const monthStart = startOfMonth(currentMonth);
    const monthEnd = endOfMonth(monthStart);
    const startDate = startOfWeek(monthStart);
    const endDate = endOfWeek(monthEnd);

    const dateFormat = "d";
    const rows = [];
    let days = [];
    let day = startDate;
    let formattedDate = "";
    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = format(day, dateFormat);
        const cloneDay = day;
        let unavailableDateIndexes = unavailableDates.multiIndexOf(
          formatDate(day)
        );
        let holdDateIndexes = holdDates.multiIndexOf(formatDate(day));

        days.push(
          <div
            className={`col cell ${
              !isSameMonth(day, monthStart)
                ? "disabled"
                : isSameDay(day, selectedDate)
                ? "selected"
                : ""
            }`}
            key={day}
            onClick={() => {
              toggle();
              setClickedDate(formatDate(cloneDay));
            }}
          >
            <span className="number">{formattedDate}</span>
            <span className="bg">{formattedDate}</span>
            {determineAvailabilityUDI(unavailableDateIndexes, holdDateIndexes)}
            {determineAvailabilityHDI(unavailableDateIndexes, holdDateIndexes)}
            <div className="cal-stat">
              {`${calculateConfirmedCompetitions(formatDate(day))} Confirmed`}
            </div>
          </div>
        );
        day = addDays(day, 1);
      }
      rows.push(
        <div className="row" key={day}>
          {days}
        </div>
      );
      days = [];
    }
    return <div className="body">{rows}</div>;
  };

  const columns = [
    {
      id: 1,
      label: "Email",
    },
    {
      id: 2,
      label: "Artist Name",
    },

    {
      id: 2,
      label: "Start Date",
    },

    {
      id: 2,
      label: "Total Prizes",
    },

    {
      id: 2,
      label: "Confirmed",
    },
    {
      id: 2,
      label: "Edit",
    },
    {
      id: 2,
      label: "Delete",
    },
  ];

  const onDateClick = (day) => {
    setSelectedDate(day);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleArtistChange = (e) => {
    setArtist(e.target.value);
  };

  const handleTotalPrizesChange = (e) => {
    setTotalPrizes(e.target.value);
  };

  const handleInvoiceAmountChange = (e) => {
    if (parseFloat(e.target.value)){
      if (parseFloat(e.target.value) > 1500) {
        setTotalPrizes(Math.round(parseFloat(e.target.value) * 0.667));
      } else {
        setTotalPrizes(parseFloat(e.target.value) - 500);
      }
      setInvoiceAmount(parseFloat(e.target.value));
    } else {
      setTotalPrizes();
      setInvoiceAmount();
    }

  };

  console.log("inoice",invoiceAmount);
  console.log("prizes",totalPrizes);
  const handleSongNameChange = (e) => {
    setSongName(e.target.value);
  };

  const handleCompetitionType = (e) => {
    setCompetitionType(e.target.value);
  }

  const handleIsClosed = (e) => {
    setIsClosed(!isClosed);
  };

  const handleGlobalContest = () => {
    setIsGlobalContest(!isGlobalContest);
  }

  const nextMonth = () => {
    setCurrentMonth(addMonths(currentMonth, 1));
  };

  const prevMonth = () => {
    setCurrentMonth(subMonths(currentMonth, 1));
  };
  const changeHasLanguageRestriction = () => {
    setHasLanguageRestrictions(!hasLanuageRestrictions);
  }
  const handleLanguageRestrcitionsChange = (e) => {
    setLanguageRestrictions(e.target.value);
  }
  const changeHasCreativeDirection = () =>{
    setHasCreativeDirection(!hasCreativeDirection);
  }
  const changeCreativeDirectionRequired = ()=> {
    setCreativeDirectionRequired(!creativeDirectionRequired);
  }
  return (
    <>
      <br />
      <br />
      <br />
      <div
        className="btn btn-primary mt-4 ml-2"
        style={{
          marginLeft: "8px",
          backgroundColor: "#6193ff",
          borderColor: "#6193ff",
        }}
        onClick={toggleReserveModal}
      >
        Reserve A Slot +
      </div>
      <div className="calendar mt-2 pt-4">
        {renderHeader()}
        {renderDays()}
        {renderCells()}
      </div>
      <Modal isOpen={modal} toggle={toggle} style={{ maxWidth: 900 }}>
        <ModalHeader toggle={toggle} tag="h4">
          {clickedDate} Competitions
        </ModalHeader>
        <ModalBody>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => {
                    return (
                      <TableCell key={column.id}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            cursor: "pointer",
                          }}
                        >
                          {column.label}
                        </div>
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {competitions.map((row, i) => {
                  const newClickedDate = `${clickedDate}`.split("-");
                  const clickedYears = newClickedDate[0];
                  const clickedMonths = newClickedDate[1];
                  const clickedDays = newClickedDate[2];
                  const selectedDateInt = new Date(
                    `${clickedMonths}/${clickedDays}/${clickedYears}`
                  ).getTime();
                  const midnightStartDate = new Date(row.startDate);
                  midnightStartDate.setHours(0, 0, 0);

                  if (
                    selectedDateInt >= midnightStartDate.getTime() &&
                    selectedDateInt <= row.endDate
                  ) {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        // onClick={() =>
                        //   window.open(`https://preffy.com/c/${row.id}`, "_blank")
                        // }
                        tabIndex={-1}
                        key={i}
                      >
                        <TableCell key={i}>{row.organizerEmail}</TableCell>
                        <TableCell key={i}>{row.organizer}</TableCell>
                        <TableCell key={i}>
                          {formatDate(row.startDate)}
                        </TableCell>
                        <TableCell key={i}>
                          {calcTotalPrizes(row.prizes)}
                        </TableCell>
                        <TableCell key={i}>
                          {row.isConfirmed ? "Confirmed" : "Hold"}
                        </TableCell>
                        <TableCell key={i}>
                          {row.organizerEmail ===
                            userInfo[0].EMAILADDRESS_PRIMARY ||
                          userInfo[0].EMAILADDRESS_PRIMARY ===
                            "charlie@songfluencer.com" ||
                          userInfo[0].EMAILADDRESS_PRIMARY ===
                            "meghan@songfluencer.com" ? (
                            <div
                              className="btn btn-secondary"
                              onClick={() => {
                                window.location.href = `/edit?id=${row.id}&LoginToken=${params.LoginToken}`
                              }}
                            >
                              Edit
                            </div>
                          ) : (
                            ""
                          )}
                        </TableCell>
                        <TableCell key={i}>
                          {row.organizerEmail ===
                            userInfo[0].EMAILADDRESS_PRIMARY ||
                          userInfo[0].EMAILADDRESS_PRIMARY ===
                            "charlie@songfluencer.com" ||
                          userInfo[0].EMAILADDRESS_PRIMARY ===
                            "meghan@songfluencer.com" ? (
                            <div
                              className="btn btn-primary"
                              onClick={() => {
                                removeHold(row);
                              }}
                            >
                              Delete
                            </div>
                          ) : (
                            ""
                          )}
                        </TableCell>

                      </TableRow>
                    );
                  }
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </ModalBody>
      </Modal>

      <Modal isOpen={reserveModal} toggle={toggleReserveModal}>
        <ModalHeader toggle={toggleReserveModal} tag="h4">
          Reserve A Date
        </ModalHeader>
        <ModalBody >
          <AvForm className="px-3">
            {!isLoading ? (
              <Row className="mt-0">
                {/* <Col md="12" className="mb-3">
                  <AvGroup className="form-group position-relative">
                    <Label>Email</Label>

                    <AvField
                      type="text"
                      className="form-control pl-5"
                      name="email"
                      id="email"
                      placeholder="Ex. example@gmail.com"
                      value={email}
                      onChange={handleEmailChange}
                      errorMessage=""
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "Please enter your email",
                        },
                        pattern: {
                          value: "^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$",
                          errorMessage: "Email is not valid!",
                        },
                      }}
                    />
                  </AvGroup>
                </Col> */}

                <Col md="6" className="mb-3">
                  <AvGroup className="form-group position-relative" style={{marginRight:5}}>
                    <Label>Artist Name</Label>
                    <AvField
                      type="text"
                      className="form-control pl-5"
                      name="artist"
                      id="artist"
                      placeholder="Ex. The Beatles"
                      value={artist}
                      onChange={handleArtistChange}
                      errorMessage=""
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "Please enter the artist's name",
                        },
                      }}
                    />
                  </AvGroup>
                </Col>

                <Col md="6" className="mb-3">
                  <AvGroup className="form-group position-relative" style={{marginLeft:5}}>
                    <Label>Song Name (Optional)</Label>
                    <AvField
                      type="text"
                      className="form-control pl-5"
                      name="song"
                      id="song"
                      placeholder="Ex. Hey Jude"
                      value={songName}
                      onChange={handleSongNameChange}
                      errorMessage=""
                    />
                  </AvGroup>
                </Col>
                  
                <Col md="12" className="mb-3">
                  <FormGroup
                    className="position-relative"
                    style={{ display: "flex", flexDirection: "column", marginRight:5 }}
                  >
                    <Label>Start Date</Label>
                    <DatePicker
                      selected={launchDate}
                      className="form-control"
                      onChange={(date) => {
                        setLaunchDate(date);
                      }}
                    />
                  </FormGroup>
                </Col>
                {/* <Col md="6" className="mb-3 ">
                  <FormGroup
                    className="position-relative"
                    style={{ display: "flex", flexDirection: "column", marginLeft:5 }}
                  >
                    <Label>End Date</Label>
                    <DatePicker
                      selected={endDate}
                      className="form-control"
                      onChange={(date) => {
                        setEndDate(date);
                      }}
                    />
                  </FormGroup>
                </Col> */}

                <Col md="12" className="mb-3">
                  <AvGroup className="form-group position-relative">
                    <Label>Invoice Amount (USD)</Label>
                    <AvField
                      type="number"
                      className="form-control pl-5"
                      name="invoiceAmount"
                      id="invoiceAmount"
                      placeholder="Ex. 1000"
                      value={invoiceAmount}
                      onChange={handleInvoiceAmountChange}
                      errorMessage=""
                      validate={{
                        required: {
                          errorMessage: "Please enter a numeric value",
                        },
                      }}
                    />
                  </AvGroup>
                </Col>
                <Col md="12" className="mb-3">
                  <AvGroup className="form-group position-relative">
                    <Label>Platforms</Label>
                    <br />
                    <select
                      className="form-control"
                      value={platforms && platforms.toString()}
                      onChange={handlePlatformsChange}
                    >
                      <option value="TikTok">TikTok</option>
                      <option value="Instagram Reel">Instagram Reel</option>
                      <option value="YouTube Short">YouTube Short</option>
                      <option value="TikTok,YouTube Short">TikTok,YouTube Short</option>
                      <option value="TikTok,Instagram Reel">TikTok,Instagram Reel</option>
                      <option value="Instagram Reel,YouTube Short">Instagram Reel,YouTube Short</option>
                      <option value="TikTok,Instagram Reel,YouTube Short">TikTok,Instagram Reel,YouTube Short</option>
                    </select>
                  </AvGroup>
                </Col>
                <Col md="12" className="mb-3">
                  <AvGroup className="form-group position-relative">
                    <Label>Prizing Type</Label>
                    <br />
                    <select
                      name="charts"
                      id="charts"
                      className="form-control"
                      value={competitionType}
                      onChange={handleCompetitionType}
                    >
                      <option value="Hybrid">Hybrid</option>
                      <option value="Engagement">Engagement</option>
                      <option value="Creative">Creative</option>
                    </select>
                    
                  </AvGroup>
                </Col>
                <Col md="12" className="mb-3">
                  <AvGroup className="form-group position-relative">
                    <Label>Is it a global contest?</Label>
                    <br />
                    <input
                      type="checkbox"
                      checked={isGlobalContest}
                      onChange={handleGlobalContest}
                    />
                  </AvGroup>
                </Col>
                {!isGlobalContest && (
                  <Col md="12" className="mb-3">
                    <AvGroup className="form-group position-relative">
                      <Label>Eligible Territories</Label>
                      <AvField
                        type="text"
                        className="form-control pl-5"
                        name="globalTerritories"
                        id="globalTerritories"
                        placeholder="Ex. US Only"
                        value={restrictedTerritories}
                        onChange={handleRestrictedTerritoriesChange}
                        errorMessage=""
                        
                      />
                    </AvGroup>
                  </Col>
                )}
                <Col md="6" className="mb-3">
                  <AvGroup className="form-group position-relative">
                    <Label>Is there a creative direction?</Label>
                    <br />
                    <input
                      type="checkbox"
                      checked={hasCreativeDirection}
                      onChange={changeHasCreativeDirection}
                    />
                  </AvGroup>
                </Col>
                {hasCreativeDirection && (
                  <Col md="6" className="mb-3">
                    <AvGroup className="form-group position-relative">
                      <Label>Is the creative direction required?</Label>
                      <br />
                      <input
                        type="checkbox"
                        checked={creativeDirectionRequired}
                        onChange={changeCreativeDirectionRequired}
                      />
                    </AvGroup>
                  </Col>
                )}
                <Col md="12" className="mb-3">
                  <AvGroup className="form-group position-relative">
                    <Label>Is there a language requirement?</Label>
                    <br />
                    <input
                      type="checkbox"
                      checked={hasLanuageRestrictions}
                      onChange={changeHasLanguageRestriction}
                    />
                  </AvGroup>
                </Col>
                {hasLanuageRestrictions && (
                  <Col md="12" className="mb-3">
                    <AvGroup className="form-group position-relative">
                      <Label>What language must the creators use?</Label>
                      <AvField
                        type="text"
                        className="form-control pl-5"
                        name="globalTerritories"
                        id="globalTerritories"
                        placeholder="Ex. English only"
                        value={languageRestrictions}
                        onChange={handleLanguageRestrcitionsChange}
                        errorMessage=""
                        
                      />
                    </AvGroup>
                  </Col>
                )}
                
                <Col md="12" className="mb-3">
                  <Label>Additional Notes/Creative Direction (Optional)</Label>
                  <CKEditor
                    editor={ClassicEditor}
                    data=""
                    height="500"
                    onReady={(editor) => {
                      // You can store the "editor" and use when it is needed.
                      console.log("Editor is ready to use!", editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      console.log({ event, editor, data });
                      setNote(data);
                    }}
                    onBlur={(event, editor) => {
                      console.log("Blur.", editor);
                    }}
                    onFocus={(event, editor) => {
                      console.log("Focus.", editor);
                    }}
                  />
                </Col>
              </Row>
            ) : (
              <h3>Please Wait... Competition Processing</h3>
            )}

            <br />
            <Row>
              <Col sm="12">
                {!isLoading ? (
                  <input
                    type="submit"
                    id="submit"
                    name="send"
                    className="btn btn-secondary float-right"
                    value={isLoading ? "Done" : "Submit"}
                    disabled={isLoading}
                    onClick={handleClick}
                  />
                ) : (
                  <div
                    style={{
                      margin: "auto",
                      width: "100%",
                      justifyContent: "center",
                    }}
                  >
                    <div className="loader" style={{ margin: "auto" }}></div>
                  </div>
                )}
              </Col>
            </Row>
          </AvForm>
        </ModalBody>
      </Modal>
    </>
  );
}

export default Calendar;
